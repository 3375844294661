import React, { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import CORN from "../../img/chains/CORN.png";
import apiClient from "../../apiClient";
import { BuyModal } from "../../components/Modal/BuyModal";
import {useMediaQuery} from "react-responsive";

const GridNFT = ({
  item,
  id,
  imageUrl,
  itemName,
  price,
  hash,
  favoriteFlag,
  favoriteCount,
  saleStatus
}) => {
  const isPc = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [favoriteIsMark, setFavoriteIsMark] = useState(false);
  const [favoriteCounting, setFavoriteCounting] = useState(0);

  const itemPath = `/item/${id}`;

  const renderPrice = () => {
    return !isPc ? (
        <div className="mt-4 flex-row items-center justify-between">
          <a href={itemPath}>
            <span className="font-display text-base text-jacarta-700 hover:text-accent dark:text-white">
              {itemName}
            </span>
          </a>
          {
            price &&
            <span className="flex items-center whitespace-nowrap place-content-center rounded-md border border-jacarta-100 py-1 px-2 dark:border-jacarta-600">
{/*            <span data-tippy-content="CORN">
              <img src={CORN} width="15" height="15" />
            </span>*/}
            <span className="text-sm font-medium tracking-tight text-green">
              {Number(price * 1000).toLocaleString("en")} 원
            </span>
            </span>
          }
        </div>
    ) : (
        <div className="mt-4 flex items-center justify-between">
          <a href={itemPath}>
            <span className="font-display text-base text-jacarta-700 hover:text-accent dark:text-white">
              {itemName}
            </span>
          </a>
          {
            price &&
            <span className="flex items-center whitespace-nowrap rounded-md border border-jacarta-100 py-1 px-2 dark:border-jacarta-600">
{/*            <span data-tippy-content="CORN">
              <img src={CORN} width="15" height="15" />
            </span>*/}
            <span className="text-sm font-medium tracking-tight text-green">
              {Number(price * 1000).toLocaleString("en")} 원
            </span>
            </span>
          }
        </div>
    )
  }

  async function doFavorites(flag, entityType, entityId) {
    const path = flag ? `social/createFavorites` : `social/destroyFavorites`;
    const { data: createFavoritesData } = await apiClient.post(path, {
      entityType,
      entityId,
    });
  }

  useEffect(() => {
    setFavoriteIsMark(favoriteFlag);
    setFavoriteCounting(parseInt(favoriteCount));
  }, []);

  return (
    <article key={id}>
      {modalIsOpen && (
        <BuyModal
          setModalIsOpen={setModalIsOpen}
          item={item}
          styledWrapper="modal-dialog max-w-2xl"
        />
      )}

      <div className="block aspect-[270/364] rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
        <figure>
          <a href={itemPath}>
            <img
              src={imageUrl}
              alt="item 1"
              width="230"
              height="230"
              className="aspect-square w-full rounded-[0.625rem]"
              loading="lazy"
            />
          </a>
        </figure>
        {
          renderPrice()
        }
        <div className="mt-8 flex items-center justify-between">
          {
            saleStatus &&
            <button
                onClick={() => {
                  // setModalIsOpen(true)
                  navigate(itemPath);
                }}
                type="button"
                className="font-display text-sm font-semibold text-accent"
                data-bs-toggle="modal"
                data-bs-target="#placeBidModal"
            >
              Buy now
            </button>
          }
          <div className="flex items-center space-x-1">
            <span
              onClick={async () => {
                const newFlag = !favoriteIsMark;
                await doFavorites(newFlag, "collectionAssets", id);
                setFavoriteIsMark((prevState) => !prevState);

                const delta = newFlag ? 1 : -1;
                console.log(`favoriteCounting = ${favoriteCounting}`);
                setFavoriteCounting((prevState) => prevState + delta);
                // alert(`FAVORITE`);
              }}
              className="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
              data-tippy-content="Favorite"
            >
              {!favoriteIsMark ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="h-4 w-4 fill-jacarta-500 hover:fill-red dark:fill-jacarta-200 dark:hover:fill-red"
                >
                  <path fill="none" d="M0 0H24V24H0z" />
                  <path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="#EF4444"
                  className="h-4 w-4"
                >
                  <path fill="none" d="M0 0H24V24H0z" />
                  <path d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228z" />
                </svg>
              )}
            </span>
            <span className="text-sm dark:text-jacarta-200">
              {favoriteCounting}
            </span>
          </div>
        </div>
      </div>
    </article>
  );
};

export { GridNFT };
